<template>
  <Navbar v-if="showComponent" />
  <div class="page-content" :class="{ 'vh-100': addClass }">
    <div class="content-wrapper">
      <div class="content-inner">
        <div
          class="content"
          :class="{
            'd-flex justify-content-center align-items-center': addClass,
          }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/commons/Navbar.vue'

export default {
  name: 'Layout',

  components: {
    Navbar,
  },

  data() {
    return {
      routesName: ['Auth', 'Unauthorized', 'NotFound'],
    }
  },
  methods: {},
  computed: {
    addClass() {
      return this.routesName.includes(this.$route.name)
    },

    showComponent() {
      return !this.routesName.includes(this.$route.name)
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
  },
}
</script>
