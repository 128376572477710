import home from './modules/home'
import group from './modules/group'
import question from './modules/question'
import report from './modules/report'

export default {
  home,
  group,
  question,
  report,
}
