const BASE_URL = '/'
const AUTH = '/auto-login'
const HOME = '/home'
const REPORTS = `${BASE_URL}reports`
const REPORTS_GENERAL = `${REPORTS}/general`
const REPORTS_MONTHLY = `${REPORTS}/monthly`
const REPORTS_MONTHLY_CHOICE_OF_THE_GUYS = `${REPORTS}/monthly-choice-of-the-guys`
const UNAUTHORIZED = '/unauthorized'
const GROUPS_DETAIL = '/groups/:uuid'

export default {
  BASE_URL,
  AUTH,
  HOME,
  REPORTS,
  REPORTS_GENERAL,
  REPORTS_MONTHLY,
  REPORTS_MONTHLY_CHOICE_OF_THE_GUYS,
  UNAUTHORIZED,
  GROUPS_DETAIL,
}
